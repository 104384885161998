import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BlackLogo from '../images/foolizzy-tns.png';

class Home extends Component {
  render() {
    return(
    <div data-bs-spy="scroll" data-bs-target="#zznavbar-the-band" data-bs-offset="0" className="scrollspy-the-band" tabIndex="0"> 
      <main>
        <div id="about" className="px-3 py-3 page-content clearfix bg-white text-black text-center">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1><Link to="/"><img src={BlackLogo} className="img-fluid" alt="FooLizzy ~ Rock and pop band"></img></Link></h1>
                <p className="lead">Wiltshire/Somerset based band celebrating the music of Thin Lizzy and The Foo Fighters plus a bit more.</p>
                <p>A Foo Lizzy night would typically consist of a set of rock covers followed by a set of Thin Lizzy and Foo Fighters but we can cater to your needs - if you want a set of each or just a set of one or the other</p>
                <p>You can expect well loved hits from the two bands and maybe a few unexpected numbers for good measure.</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    )
  }

}

export default Home;