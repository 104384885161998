import './App.css';
import useFetch from './useFetch';
import {BrowserRouter as Router} from 'react-router-dom';
import Home from './components/inc/Home';
import Banner from './components/inc/Banner';
import Footer from './components/inc/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function App() {
  const {data, loading, error} = useFetch("gigs.json");
  
  if (loading) return <h1>Loading gigs...</h1>
  if (error) console.log(error);
  
  return (
    <Router>
      {/* <Header /> */}
      <header className="mb-auto">
      <div className="container">
        <nav className="navbar navbar-expand-sm navbar-dark bg-black">
          <div className="container-fluid">
            <a className="navbar-brand" href="/"><img src="foolizzy.png" height="50px" width="auto" alt="FooLizzy" /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-the-band" aria-controls="navbar-the-band" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse float-md-end" id="navbar-the-band">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#top">Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#about">About</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#gigs">Gigs</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contact">Contact</a>
                </li>
              </ul>
              <div className="d-flex text-white">
                <div className="p-2 bd-highlight"><a href="https://www.facebook.com/FooLizzy"><FontAwesomeIcon icon="fa-brands fa-facebook" size="2x" color="white" /></a></div>
                <div className="p-2 bd-highlight"><a href="https://www.youtube.com/@foolizzy"><FontAwesomeIcon icon="fa-brands fa-youtube" size="2x" color="white" /></a></div>
                <div className="p-2 bd-highlight"><a href="https://www.instagram.com/foolizzyband/"><FontAwesomeIcon icon="fa-brands fa-instagram" size="2x" color="white" /></a></div>
              </div>
            </div>
          </div>
        </nav>
        <div className="d-flex d-none d-md-block float-end">
          <div className="p-2 bd-highlight text-danger">Next gig... {data?.gigs[0].date} at {data?.gigs[0].venuename}, {data?.gigs[0].venuetown} ({data?.gigs[0].time}) {data?.gigs[0].eventname}</div>
        </div>
      </div>
    </header>
      <Banner />
      <div className="container d-md-none">
        <div className="d-flex bd-highlight text-white">
          <div className="p-2 flex-grow-1 bd-highlight text-alert">
            <button class="btn btn-danger" type="button" disabled>
              <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span class="px-2">Next gig... {data?.gigs[0].date} at {data?.gigs[0].venuename}, {data?.gigs[0].venuetown} ({data?.gigs[0].time}) {data?.gigs[0].eventname}</span>
            </button>
          </div>
        </div>
      </div>
      <Home />
      <div id="gigs" className="px-4 py-4">
      <h2 className="text-white text-center gy-4">{data?.heading}</h2>
      <div className="row row-cols-1 row-cols-sm-3 g-4 py-4">
        
      {data?.gigs.map(({date,time,id,eventname,venuename,venuetown,venueaddress}) => (
        <div className="col" key={id}>
        <div className="card h-100">
          <div className="card-body">
            <h5 className="card-title">{date}</h5>
            <h6 className="card-text">{time}</h6>
            <p className="card-text">{venuename}, {venuetown}</p>
            <p className="card-text"><small>{venueaddress}</small></p>
            <div className="class-footer">
              <small className="text-muted">{eventname}</small>
            </div>
          </div>
        </div>
        </div>
        ))}
      </div>
    </div>
      <Footer />
    </Router>
  );
}

export default App;
